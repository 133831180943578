import React, { useState } from 'react'
import { graphql } from 'gatsby'
import { LocaleContext } from '../components/layout'
import tw, { styled } from 'twin.macro'
import SEO from '../components/SEO/SEO'
import { withPreview } from 'gatsby-source-prismic'
import Logo from '../images/logo-square.png'
// styles
const ImprintWrapper = styled.div`
  h3 {
    margin-bottom: 32px;
  }
  p {
    margin-bottom: 32px;
  }
`
// markup
const ImprintPage = ({ data: { prismicImprint }, pageContext: { locale } }) => {
  const lang = React.useContext(LocaleContext)
  const i18n = lang.i18n[lang.locale]

  return (
    <main>
      <SEO
        title={prismicImprint.data.meta_title}
        desc={prismicImprint.data.meta_description}
        locale={locale}
      />
      <ImprintWrapper tw="container py-32">
        <div tw="grid grid-cols-2 gap-32 items-center">
          <div>
            <div
              dangerouslySetInnerHTML={{
                __html: prismicImprint.data.content.html,
              }}
              tw="text-lg text-ocean"></div>
          </div>
        </div>
      </ImprintWrapper>
    </main>
  )
}

export default withPreview(ImprintPage)

export const pageQuery = graphql`
  query ImprintQuery($locale: String!) {
    prismicImprint(lang: { eq: $locale }) {
      data {
        meta_description
        meta_title
        content {
          html
        }
      }
      uid
    }
  }
`
